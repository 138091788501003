import {
  Button,
  Center,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  Link
} from "@chakra-ui/react";
import { SupportingOrganizationComponent } from "modules/patient/components/supporting-organization.component";
import React from "react";
import { BindulogicCreditsComponent } from "./bindulogic-credits.component";
import { FaArrowLeft } from "react-icons/fa";
import { ContactAddressComponent } from "./contact-address.component";
import { env } from "app/config";

interface AboutUsComponentProps {}

export const AboutProjectComponent: React.FC<AboutUsComponentProps> = (
  props: AboutUsComponentProps
) => {
  return (
    <Stack height="100vh" justify="space-evenly" direction="column">
      <Stack align={"center"}>
        <Heading textDecoration="bold">About the Project</Heading>
        <Stack
          textAlign={"justify"}
          justify={"center"}
          align="center"
          width={{ base: "90%", md: "50%" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <Text>
            This online appointment management system for Combined Military
            Hospital (CMH) is designed to streamline patient services and
            enhance operational efficiency. 
            <br />
            The project is proudly supervised by
            <Text as="span" fontWeight="bold">
              {" "}
              1 Signal Battalion
            </Text>
            , whose expert guidance and oversight have been instrumental in its
            development. 
            <br />
            The technical development and implementation of the
            system have been expertly handled by
            <Text as="span" fontWeight="bold">
              {" "}
              Bindulogic Limited
            </Text>
            , ensuring robust software solutions and reliable technical
            assistance.
            <br />
            This initiative represents a collaborative effort to
            modernize military healthcare services, enabling seamless appointment
            scheduling for patients while improving overall hospital management.
          </Text>
        </Stack>
      </Stack>
      <Stack>
        <Center width="100%" textAlign="center" pb={4}>
          <Stack
            direction="row"
            width="100%"
            justify={"space-evenly"}
            alignItems="center"
          >
            <Image src={env.supportingOrganizationImageLink} width="8rem" objectFit="contain" />
            <Image
              src="/images/bindulogic-limited-logo.png"
              width="16rem"
              objectFit="contain"
            />
          </Stack>
        </Center>

        <ContactAddressComponent />
      </Stack>
    </Stack>
  );
};
