import { IPage } from "app/types";
import { BookingPage } from "../pages/booking.page";
import { UnsuccessfulPaymentPage } from "../pages/unsuccessful-payment.page";
import { SuccessfulPaymentPage } from "../pages/successful-payment.page";
import { TicketDetailsPage } from "../pages/ticket-details.page";
import { PrescriptionTestPage } from "../pages/prescription-test.page";
import { TermsPrivacyRefundPoliciesPage } from "modules/core/pages/terms-privacy-refund-policies.page";
import { AboutUsPage } from "modules/core/pages/about-us.page";
import { AboutProjectPage } from "modules/core/pages/about-project.page";

export const patientPublicRoutes: IPage[] = [
  {
    name: "Booking",
    link: "/patient/booking",
    content: <BookingPage />
  },
  {
    name: "Payment Success",
    link: "/payment/success",
    content: <SuccessfulPaymentPage />
  },
  {
    name: "Payment Failure",
    link: "/payment/failure",
    content: <UnsuccessfulPaymentPage />
  },
  {
    name: "Payment Cancellation",
    link: "/paymnet/cancel",
    content: <UnsuccessfulPaymentPage />
  },
  {
    name: "Ticket Details",
    link: "/ticket/details",
    content: <TicketDetailsPage />
  },
  {
    name: "Prescription test",
    link: "/prescription/test",
    content: <PrescriptionTestPage />
  },
  {
    name: "Policies and Terms & Conditions",
    link: "/terms-and-conditions",
    content: <TermsPrivacyRefundPoliciesPage />
  },
  {
    name: "About Us",
    link: "/about",
    content: <AboutUsPage />
  },
  {
    name: "About Project",
    link: "/about-project",
    content: <AboutProjectPage />
  }
];
