import { Stack } from "@chakra-ui/react";
import React from "react";

import { useNavigate } from "react-router-dom";
import { AboutProjectComponent } from "../components/about-project.component";

export const AboutProjectPage: React.FC = () => {
  return (
    <Stack height="100vh" justify="space-between">
      <AboutProjectComponent />
    </Stack>
  );
};
