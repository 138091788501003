import { Center, Image, Link, Stack, Text } from "@chakra-ui/react";
import { env } from "app/config";
import React from "react";

interface SupportingOrganizationComponentProps {}

export const SupportingOrganizationComponent: React.FC<
  SupportingOrganizationComponentProps
> = (props: SupportingOrganizationComponentProps) => {
  return (
    <Center width="100%" textAlign="center" pt="16" pb={4}>
      {env.isAppointment === "true" ? (
        <Stack
          direction="row"
          width="100%"
          justify={"space-around"}
          alignItems="center"
        >
          <Image src={env.ministryImageLink} width="8rem" objectFit="contain" />
          <Image
            src={env.supportingOrganizationImageLink}
            width="8rem"
            objectFit="contain"
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          width="100%"
          justify={"space-around"}
          alignItems="center"
        >
          <Image
            src="/images/dghs-logo.png"
            height={{ base: 12, lg: 20 }}
            objectFit="contain"
          />
          <Image
            src="/images/bindulogic-limited-logo.png"
            height={{ base: 8, lg: 12 }}
            objectFit="contain"
          />
          <Image
            src="/images/mis-logo.png"
            height={{ base: 12, lg: 20 }}
            objectFit="contain"
          />
        </Stack>
      )}
    </Center>
  );
};
