import { Stack, Center, Text, Image, Link } from "@chakra-ui/react";
import React from "react";

interface ContactAddressComponentProps {}

export const ContactAddressComponent: React.FC<ContactAddressComponentProps> = (
  props: ContactAddressComponentProps
) => {
  return (
    <Stack>
      <Center>
        <Stack direction={{ base: "column", lg: "row" }} alignItems="center">
          <Text color="gray.500" fontSize={{ base: "sm" }}>
            Software By
          </Text>
          <Center>
            <Stack direction="row">
              <Image
                src="/images/bindulogic-logo.png"
                width={4}
                objectFit="cover"
              />
              <Link
                color="blue.400"
                href="https://bindulogic.com/"
                isExternal
                fontSize={{ base: "sm" }}
              >
                <Text as="span" fontWeight={"bold"}>
                  bindulogic limited{" "}
                </Text>{" "}
              </Link>{" "}
            </Stack>{" "}
          </Center>
        </Stack>{" "}
      </Center>
      <Center>
        <Text textAlign="center" color="gray.500" fontSize="xs">
          Address: House 07, Road 02, Block B, Mirpur 10, Dhaka 1216 | Contact:
          01302606005 | Email: contact@bindulogic.com
        </Text>
      </Center>
    </Stack>
  );
};
