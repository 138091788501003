import React from "react";
import { Center, Heading, Image, Link, Stack, Text } from "@chakra-ui/react";
import { SearchPatientComponent } from "../components/search-patient.component";
import { FoundPatientListComponent } from "../components/found-patient-list.component";
import { BindulogicCreditsComponent } from "modules/core/components/bindulogic-credits.component";
import { SupportingOrganizationComponent } from "../components/supporting-organization.component";
import { env } from "app/config";
import { useDocumentTitle } from "hooks/use-document-title.hook";

interface BookingPageProps {}

export const BookingPage: React.FC<BookingPageProps> = (
  props: BookingPageProps
) => {
  useDocumentTitle("E-Ticket | " + env.appName);
  return (
    <Center width="100%">
      <Stack width={{ base: "100%", lg: "1200px" }}>
        <Stack justifyContent={"space-between"} minHeight="100vh">
          <Stack spacing="12">
            <Stack pt={12}>
              <Center>
                <Image
                  src={env.organizationLogo}
                  objectFit={"cover"}
                  width={{ base: "20", lg: "32" }}
                />
              </Center>
              <Center width="100%">
                <Heading
                  textAlign={"center"}
                  fontFamily={"Kalpurush"}
                  fontSize={{ base: "xl", lg: "4xl" }}
                >
                  {env.organizationName.toUpperCase()}
                </Heading>
              </Center>
              <Center width="100%">
                <Heading
                  textAlign={"center"}
                  fontFamily={"Kalpurush"}
                  fontSize={{ base: "md", lg: "2xl" }}
                  color="gray.600"
                >
                  {env.isAppointment === "true"
                    ? "APPOINTMENT PLATFORM"
                    : "অনলাইন অ্যাপয়েন্টমেন্ট ও ই-টিকেটিং প্ল্যাটফর্ম"}
                </Heading>
              </Center>
              <Center width="100%">
                <Heading
                  textAlign={"center"}
                  fontFamily={"Kalpurush"}
                  fontSize={{ base: "sm", lg: "3xl" }}
                  color="gray.600"
                >
                  {env.isAppointment === "true"
                    ? "BOOK YOUR APPOINTMENT"
                    : "অনুগ্রহ করে টিকিট কাটার জন্য আপনার ফোন নম্বরটি প্রদান করুন -"}
                </Heading>
              </Center>
            </Stack>
            <Stack>
              <SearchPatientComponent />
              <FoundPatientListComponent />
            </Stack>
          </Stack>

          <Stack spacing="0">
            <SupportingOrganizationComponent />
            {env.isAppointment !== "true" ? (
              <BindulogicCreditsComponent />
            ) : (
              <Center
                width="100%"
                textAlign="center"
                pt={{ base: 4, lg: 8 }}
                pb={4}
              >
                <Stack direction="column" width="100%">
                  <Center>
                    <Link
                      href="/about-project"
                      fontWeight={"semibold"}
                      color={"gray.500"}
                      textDecoration={"underline"}
                      isExternal
                    >
                      © 1 Signal Battalion, Bangladesh Army
                    </Link>
                  </Center>
                </Stack>
              </Center>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Center>
  );
};
