//This component is used for JASHORE,CMH.

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  useToast,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Spacer,
  Checkbox,
  Link,
  Spinner,
  RadioGroup,
  Radio,
  Heading,
  ButtonGroup,
  Divider,
  Flex,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import {
  Location as LocationType,
  PatientWithDetails,
  TimeSlot
} from "app/api/type";
import dayjs from "dayjs";
import {
  useBookOnlineAppointmentMutation,
  useGetAllDepartmentsQuery,
  useGetHospitalQuery,
  useGetPatientTicketHistoryQuery,
  useLazyGetAllTimeSlotsForLocationQuery,
  useSendAppointmentSmsMutation,
  useStartPaymentMutation
} from "../api";
import { Select } from "chakra-react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { FaInfoCircle, FaTicketAlt } from "react-icons/fa";
import { env } from "app/config";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { PrintableOpdTicketComponent } from "./printable-opd-ticket.component";

interface BookTicketModalType3ComponentProps {
  patientWithDetails: PatientWithDetails;
}

export const BookTicketModalType3Component: React.FC<
  BookTicketModalType3ComponentProps
> = (props: BookTicketModalType3ComponentProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSecondModalOpen,
    onOpen: openSecondModal,
    onClose: closeSecondModal
  } = useDisclosure();

  const toast = useToast();
  const [sendAppointmentSms, sendAppointmentSmsResult] =
    useSendAppointmentSmsMutation();
  const getHospitalResult = useGetHospitalQuery({});

  const patientState = useTypedSelector((state) => state.patient);
  const getAllDepartmentsResult = useGetAllDepartmentsQuery({});
  const [bookTicket, bookTicketResult] = useBookOnlineAppointmentMutation();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    label: string;
    value: TimeSlot;
  } | null>(null);
  const [bookingDate, setBookingDate] = useState<Date | undefined>();
  const [selectedDepartment, setSelectedDepartment] = useState<{
    value: string;
    label: string;
  }>();
  const [patientPhoneNumber, setPatientPhoneNumber] = useState<string>(
    props.patientWithDetails.person.contactNumber || ""
  );

  const [getTimeSlots, getTimeSlotsResult] =
    useLazyGetAllTimeSlotsForLocationQuery();

  const [timeSlotsList, setTimeSlotList] = useState<
    {
      label: string;
      value: TimeSlot;
      isDisabled: boolean;
    }[]
  >([]);

  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const getTimeSlotsForSelectedDates = () => {
    const locationId = selectedDepartment?.value || null;
    if (locationId && bookingDate) {
      getTimeSlots({
        locationId,
        date: bookingDate.toISOString()
      });
    }
  };
  useEffect(() => {
    getTimeSlotsForSelectedDates();
  }, []);

  useEffect(() => {
    getTimeSlotsForSelectedDates();
  }, [bookingDate, selectedDepartment]);

  const getPatientTicketHistoryResult = useGetPatientTicketHistoryQuery(
    {
      patientId: props.patientWithDetails.patient.id,
      pageSize: 100,
      pageIndex: 0
    },
    {
      pollingInterval: 60000
    }
  );

  const prepareTimeSlotOptions = (timeSlotList: TimeSlot[]) => {
    return timeSlotList.map((timeSlot) => {
      if (!getPatientTicketHistoryResult.isSuccess) {
        return {
          label: `${timeSlot.locationTimeSlotGeneratorStartTime} - ${timeSlot.locationTimeSlotGeneratorEndTime}`,
          value: timeSlot,
          isDisabled: false
        };
      }
      const ticketsForThisDate =
        getPatientTicketHistoryResult.data.listOfTickets.filter((ticket) => {
          const timeSlotDate = dayjs(ticket.ticket.timeSlotDate);
          const currentDate = dayjs(bookingDate);
          return timeSlotDate.isSame(currentDate, "day");
        });
      const timeSlotsAlreadyBookedForThisDate = ticketsForThisDate.map(
        (ticket) => ticket.ticket.timeSlotSerial
      );

      return {
        label: `${timeSlot.locationTimeSlotGeneratorStartTime} - ${timeSlot.locationTimeSlotGeneratorEndTime}`,
        value: timeSlot,
        isDisabled: timeSlotsAlreadyBookedForThisDate.includes(timeSlot.serial)
      };
    });
  };

  useEffect(() => {
    if (getTimeSlotsResult.isSuccess) {
      const timeSlotOptions = prepareTimeSlotOptions(
        getTimeSlotsResult.data.timeSlots
      );
      setTimeSlotList(timeSlotOptions);
    }
  }, [
    getTimeSlotsResult,
    bookingDate,
    selectedDepartment,
    getPatientTicketHistoryResult
  ]);

  useEffect(() => {
    setSelectedTimeSlot(null);
  }, [bookingDate]);

  useEffect(() => {
    setBookingDate(undefined);
  }, [selectedDepartment]);

  const filterDate = (date: Date) => {
    const day = date.getDay();
    return day !== 5;
  };

  useEffect(() => {
    if (!isTrue) {
      const medicalInstructionRoom = departmentList.find(
        (dept) => dept.label === "Medical Instruction Room"
      );
      setSelectedDepartment(medicalInstructionRoom || departmentList[0]);
    }
  }, [isTrue, departmentList]);

  useEffect(() => {
    if (getAllDepartmentsResult.data) {
      setDepartmentList(
        getAllDepartmentsResult.data.locations.map((location) => {
          return {
            label: location.displayName,
            value: location.id
          };
        })
      );
    }
  }, [getAllDepartmentsResult]);

  const [finalBookingSlotString, setFinalBookingSlotString] = useState("");
  const handleBookTicket = () => {
    if (bookingDate && selectedTimeSlot) {
      setFinalBookingSlotString(selectedTimeSlot.label);
      bookTicket({
        data: {
          otp: patientState.otp!,
          contactNumber: patientState.phoneNumber!,
          ticket: {
            patientId: props.patientWithDetails.patient.id,
            sourceLocationId: env.onlineLocationId,
            assignedLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            ticketCategoryName: "Free",
            bookedFor: bookingDate,
            timeSlotDate: bookingDate,
            timeSlotLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            timeSlotSerial: selectedTimeSlot
              ? selectedTimeSlot.value.serial
              : 0,
            userId: null
          }
        }
      });
    }
  };

  useEffect(() => {
    if (bookTicketResult.isSuccess) {
      toast({
        title: "Appointment Booked Successfully",
        description: "Please check your details below",
        position: "top",
        status: "success",
        duration: 5000,
        isClosable: true
      });
      getPatientTicketHistoryResult.refetch();
      setBookingDate(undefined);
      onClose();
      openSecondModal();
    }
  }, [bookTicketResult]);

  useEffect(() => {
    if (
      bookTicketResult.isSuccess &&
      bookTicketResult.data &&
      selectedTimeSlot
    ) {
      sendAppointmentSms({
        data: {
          phoneNumber: patientPhoneNumber,
          assignedLocationName:
            bookTicketResult.data?.ticketWithDetails.assignedLocation.name,
          bookingDate: dayjs(
            bookTicketResult.data?.ticketWithDetails.ticket.bookedFor
          ).format("DD-MM-YYYY"),
          slot: selectedTimeSlot.label,
          serial:
            bookTicketResult.data?.ticketWithDetails.ticket.serial?.toString() ||
            ""
        }
      });
    }
  }, [bookTicketResult]);

  const InformationRow = (informationRowProps: {
    label: string;
    value: string;
  }) => {
    return (
      <Flex justifyContent={"space-between"}>
        <Text fontWeight="bold">{informationRowProps.label}</Text>
        <Text>{informationRowProps.value}</Text>
      </Flex>
    );
  };
  return (
    <>
      <Button
        colorScheme={"blue"}
        onClick={onOpen}
        leftIcon={<FaTicketAlt />}
        fontSize={"lg"}
      >
        Book Appointment
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setBookingDate(undefined);
        }}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <FormControl isReadOnly>
                <FormLabel>Patient ID</FormLabel>
                <Input value={props.patientWithDetails.patient.id} />
              </FormControl>

              <FormControl isReadOnly>
                <FormLabel>Patient Name</FormLabel>
                <Input
                  value={
                    props.patientWithDetails.person.name
                      ? props.patientWithDetails.person.name
                      : "Unknown"
                  }
                />
              </FormControl>

              <RadioGroup defaultValue="2">
                <HStack gap="6">
                  <Text>Do you know which department to visit?</Text>
                  <Radio
                    value="1"
                    onChange={() => {
                      setIsTrue(true);
                      setSelectedDepartment(undefined);
                    }}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value="2"
                    onChange={() => {
                      setIsTrue(false);
                    }}
                  >
                    No
                  </Radio>
                </HStack>
              </RadioGroup>

              <FormControl id="department" isRequired>
                <FormLabel>Department</FormLabel>
                {isTrue ? (
                  <Select
                    menuPlacement="top"
                    selectedOptionStyle="check"
                    options={departmentList}
                    value={selectedDepartment}
                    onChange={(event) => {
                      if (event) {
                        setSelectedDepartment({
                          label: event.label,
                          value: event.value
                        });
                      }
                    }}
                  />
                ) : (
                  <Input
                    placeholder="Department"
                    value={selectedDepartment?.label || ""}
                    readOnly
                  />
                )}
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Booking Date</FormLabel>
                <DatePicker
                  selected={bookingDate}
                  filterDate={filterDate}
                  onChange={(date) => date && setBookingDate(date)}
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // minDate should be tomorrow if 12 PM has passed in GMT+6
                  minDate={
                    dayjs().tz("Asia/Dhaka").hour() >= 11
                      ? dayjs().tz("Asia/Dhaka").add(1, "day").day() === 5
                        ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate()
                        : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
                      : dayjs().tz("Asia/Dhaka").toDate()
                  }
                  maxDate={dayjs().tz("Asia/Dhaka").add(14, "days").toDate()}
                  excludeDates={
                    env.excludedDates
                      ?.split(",")
                      .map((date) => dayjs(date.trim()).toDate()) || []
                  }
                />
              </FormControl>

              <FormControl id="timeSlot" isRequired>
                <FormLabel>Time Slot</FormLabel>
                <Select
                  isClearable
                  menuPlacement="top"
                  selectedOptionStyle="check"
                  options={timeSlotsList}
                  value={selectedTimeSlot}
                  onChange={(event) => {
                    if (event) {
                      setSelectedTimeSlot({
                        label: event.label,
                        value: event.value
                      });
                    } else {
                      setSelectedTimeSlot(null);
                    }
                  }}
                />
              </FormControl>

              {selectedTimeSlot && selectedDepartment && bookingDate && (
                <Alert
                  status={
                    (
                      selectedTimeSlot
                        ? selectedTimeSlot.value.currentPatientCount >=
                          selectedTimeSlot.value.maxPatientCount
                        : false
                    )
                      ? "warning"
                      : "info"
                  }
                >
                  <AlertIcon />
                  <Text fontWeight={"bold"}>
                    Remaining Slots available for{" "}
                    {dayjs(bookingDate).format("DD MMMM YYYY")} at{" "}
                    {selectedTimeSlot.label} is -
                    <br />
                    {selectedTimeSlot.value.maxPatientCount -
                      selectedTimeSlot.value.currentPatientCount}
                  </Text>
                </Alert>
              )}

              <FormControl>
                <FormLabel fontWeight={"bold"}>
                  Patient Phone Number (For Confirmation SMS)
                </FormLabel>
                <Input
                  placeholder="Enter the phone number to receive confirmation SMS."
                  value={patientPhoneNumber}
                  onChange={(event) =>
                    setPatientPhoneNumber(event.target.value)
                  }
                />
              </FormControl>

              <Checkbox defaultChecked>
                <Text as="span">
                  I agree to the{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Refund Policy
                  </Link>
                </Text>
              </Checkbox>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <Spacer />
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => {
                  onClose();
                  setBookingDate(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!bookingDate || !selectedDepartment}
                colorScheme="blue"
                onClick={handleBookTicket}
                isLoading={bookTicketResult.isLoading}
              >
                Book Appointment
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSecondModalOpen}
        onClose={closeSecondModal}
        isCentered
        size={{ base: "lg", lg: "xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Appointemnt Booking Successful!</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={"70px"} mt="4">
            <Stack spacing="4">
              <Stack
                width={{ base: "95%", md: "90%", lg: "100%" }}
                spacing={4}
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                p={4}
                textAlign="left"
              >
                <InformationRow
                  label="Issue Time:"
                  value={dayjs(
                    bookTicketResult.data?.ticketWithDetails.ticket.issueTime
                  ).format("hh:mm a, MMM DD, YYYY")}
                />
                <InformationRow
                  label="Booked For:"
                  value={dayjs(
                    bookTicketResult.data?.ticketWithDetails.ticket.bookedFor
                  ).format("MMM DD, YYYY")}
                />
                <InformationRow
                  label="Slot:"
                  value={
                    finalBookingSlotString || selectedTimeSlot?.label || "-"
                  }
                />
                <InformationRow
                  label="Serial:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.ticket.serial?.toString() ||
                    "-"
                  }
                />
                <InformationRow
                  label="Assigned Department:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.assignedLocation
                      .name || "-"
                  }
                />
                <InformationRow
                  label="Patient ID:"
                  value={
                    bookTicketResult.data?.patientWithDetails.patient.id || "-"
                  }
                />
                <InformationRow
                  label="Patient Name:"
                  value={
                    bookTicketResult.data?.patientWithDetails.person.name || "-"
                  }
                />
                <InformationRow
                  label="Symptom:"
                  value={
                    bookTicketResult.data?.ticketWithDetails.ticket.symptom ||
                    "-"
                  }
                />

                <Stack
                  direction="column"
                  width="100%"
                  alignItems={"center"}
                  justify="center"
                >
                  {bookTicketResult.data &&
                  bookTicketResult.data.ticketWithDetails.ticket.isPaid &&
                  getHospitalResult.data ? (
                    <PDFDownloadLink
                      document={
                        <PrintableOpdTicketComponent
                          ticketWithDetails={
                            bookTicketResult.data.ticketWithDetails
                          }
                          patientWithDetails={
                            bookTicketResult.data.patientWithDetails
                          }
                          personIdentifiers={[
                            bookTicketResult.data.patientWithDetails
                              .personIdentifiers
                          ]}
                          hospitalLocation={getHospitalResult.data.location}
                          printingLocation={
                            bookTicketResult.data.ticketWithDetails
                              .sourceLocation
                          }
                          printerPerson={
                            bookTicketResult.data.patientWithDetails.person
                          }
                          slotString={finalBookingSlotString}
                        />
                      }
                      fileName={`${bookTicketResult.data.ticketWithDetails.ticket.id}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button
                            colorScheme="blue"
                            width="100%"
                            isLoading
                            isDisabled
                          >
                            Loading...
                          </Button>
                        ) : (
                          <Button colorScheme="blue" width="100%">
                            Print Appointment Slip
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : (
                    <Button colorScheme="blue" isDisabled>
                      Print Appointment Slip
                    </Button>
                  )}
                </Stack>
              </Stack>

              <Divider />
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
