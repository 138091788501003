import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { BookTicketModalComponent } from "./book-ticket-modal.component";
import { Patient, Address, Person, PersonIdentifier } from "app/api/type";
import { TicketHistoryModalComponent } from "./ticket-history-modal.component";

interface PatientSummaryComponentProps {
  targetPatient: {
    patient: Patient;
    person: Person;
    address?: Address;
  };
  personIdentifiers?: PersonIdentifier[];
}

export const PatientSummaryComponent: React.FC<PatientSummaryComponentProps> = (
  props: PatientSummaryComponentProps
) => {
  const organizationId = props.personIdentifiers?.length
    ? props.personIdentifiers.find(
        (identifier) =>
          identifier.identifierTypeName === "Organization Identification Number"
      )?.identifierValue
    : undefined;
  const healthId = props.personIdentifiers?.length
    ? props.personIdentifiers.find(
        (identifier) =>
          identifier.identifierTypeName === "Health Identification Number"
      )?.identifierValue
    : undefined;
  return (
    <Stack
      borderRadius={"md"}
      boxShadow="md"
      p={4}
      justify="space-between"
      alignItems={"center"}
      direction="row"
    >
      <Stack>
        <Text fontSize={"sm"}>
          
          {props.targetPatient.patient.id}
          {organizationId && " (" + organizationId + ")"}
        
          {healthId && " (" + healthId + ")"}
        </Text>
        <Text fontSize={"lg"}>
          {props.targetPatient.person.name
            ? props.targetPatient.person.name
            : "Unknown"}
        </Text>
        <Text>
          {props.targetPatient.person.contactNumber
            ? props.targetPatient.person.contactNumber
            : "Unknown"}
        </Text>
      </Stack>
      <Stack>
        <BookTicketModalComponent patientWithDetails={props.targetPatient} />
        <TicketHistoryModalComponent patientWithDetails={props.targetPatient} />
      </Stack>
    </Stack>
  );
};
