import { Center, Image, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ContactAddressComponent } from "./contact-address.component";

interface BindulogicCreditsComponentProps {}

export const BindulogicCreditsComponent: React.FC<
  BindulogicCreditsComponentProps
> = (props: BindulogicCreditsComponentProps) => {
  return (
    <Center width="100%" textAlign="center" pt={{ base: 4, lg: 8 }} pb={4}>
      <Stack direction="column" width="100%">
        <Text color="gray.500" fontSize="sm">
          Contact Us
        </Text>

        <ContactAddressComponent />
      </Stack>
    </Center>
  );
};
